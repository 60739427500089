import React from 'react';
import { useTheme, Container, Box, Paper } from '@mui/material';
import { tokens } from '../theme';
import Header from '../components/Header';
import Warning from '../components/Warning';
import ComplaintEmail1 from '../assets/ComplaintEmail1.png';
import ComplaintEmail2 from '../assets/ComplaintEmail2.png';
import ComplaintEmail3 from '../assets/ComplaintEmail3.png';

const FormalComplaint = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Container
      sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <Box
        sx={{ position: 'sticky', top: 0, zIndex: 1000, bgcolor: 'inherit' }}
      >
        <Warning />
      </Box>

      <Header
        title="FORMAL COMPLAINT"
        subtile="Ascenson St. Vincent Hospitals: Unscrupulous - Beware - Formal Complaint"
      />

      {/* Main content box */}
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          flexGrow: 1,
          padding: 2,
        }}
      >
        {/* Paper with content */}
        {[ComplaintEmail1, ComplaintEmail2, ComplaintEmail3].map(
          (imgSrc, idx) => (
            <Paper
              elevation={5}
              sx={{
                bgcolor: colors.primary[400],
                borderRadius: '6px',
                padding: '20px',
                width: 'calc(100% + 40px)',
                margin: '0 -20px 20px -20px',
              }}
            >
              <img
                src={imgSrc}
                alt={`Formal Complaint ${idx + 1}`}
                style={{ borderRadius: '6px', width: '100%', height: 'auto' }}
              />
            </Paper>
          )
        )}
      </Box>
    </Container>
  );
};

export default FormalComplaint;
