import React from 'react';
import { useTheme, Container, Box, Paper, Typography } from '@mui/material';
import { tokens } from '../theme';
import Header from '../components/Header';
import Warning from '../components/Warning';
import AttorneyLetter1 from '../assets/AttorneyLetter12120231.png';
import AttorneyLetter2 from '../assets/AttorneyLetter12120232.png';
import StacyHanefeld from '../assets/StacyHanefeld.jpeg';

const AttorneyLetter = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Container
      sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <Box
        sx={{ position: 'sticky', top: 0, zIndex: 1000, bgcolor: 'inherit' }}
      >
        <Warning />
      </Box>

      <Header
        title="ATTORNEY'S LETTER"
        subtile="Ascenson St. Vincent Hospitals: Unscrupulous - Beware - Attorney's Letter"
      />
      <Paper
        elevation={5}
        sx={{
          bgcolor: colors.primary[400],
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '6px',
          padding: '20px',
          display: 'flex',
          flexDirection: 'row',
          // marginBottom: '20px',
        }}
      >
        <img
          src={StacyHanefeld}
          alt="Stacy Hanefeld"
          width="120px"
          height="120px"
          style={{ borderRadius: '10px' }}
        />
        <Box
          sx={{
            marginLeft: '40px',
          }}
        >
          <Typography variant="h4" fontWeight="bold">
            STACY HANEFELD - PARTNER AT DREWRY SIMMONS VORNEHM, LLP
            <br />
            <br />
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ color: colors.redAccent[500] }}
            >
              The person I trusted the most, whom I believed to be genuinely
              sympathetic toward me, turned out to be deceiving me all along...
            </Typography>
          </Typography>
        </Box>
      </Paper>

      {/* Main content box */}
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          flexGrow: 1,
          padding: 2,
        }}
      >
        {/* Paper with content */}
        {[AttorneyLetter1, AttorneyLetter2].map((imgSrc, idx) => (
          <Paper
            elevation={5}
            sx={{
              bgcolor: colors.primary[400],
              borderRadius: '6px',
              padding: '20px',
              width: 'calc(100% + 40px)',
              // Top Right Bottom Left
              margin: '0 -20px 20px -20px',
            }}
          >
            <img
              src={imgSrc}
              alt={`Attorney Letter ${idx + 1}`}
              style={{ borderRadius: '6px', width: '100%', height: 'auto' }}
            />
          </Paper>
        ))}
      </Box>
    </Container>
  );
};

export default AttorneyLetter;
