import React from 'react';
import { useTheme, Container, Box, Paper } from '@mui/material';
import { tokens } from '../theme';
import Header from '../components/Header';
import Warning from '../components/Warning';
import EMTALALetterScanned from '../assets/EMTALALetter.png';

const EMTALALetter = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Container
      sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <Box
        sx={{ position: 'sticky', top: 0, zIndex: 1000, bgcolor: 'inherit' }}
      >
        <Warning />
      </Box>

      <Header
        title="EMTALA'S LETTER"
        subtile="Ascenson St. Vincent Hospitals: Unscrupulous - Beware - EMTALA'S Letter"
      />

      {/* Main content box */}
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          flexGrow: 1,
          padding: 2,
        }}
      >
        {/* Paper with content */}
        <Paper
          elevation={5}
          sx={{
            bgcolor: colors.primary[400],
            borderRadius: '6px',
            padding: '20px',
            width: 'calc(100% + 40px)',
            // Top Right Bottom Left
            margin: '0px -20px 0 -20px',
          }}
        >
          <img
            src={EMTALALetterScanned}
            alt="EMTALA Letter"
            style={{ borderRadius: '15px', width: '100%', height: 'auto' }}
          />
        </Paper>
      </Box>
    </Container>
  );
};

export default EMTALALetter;
