import React, { useState } from 'react';
import { Box, IconButton, Typography, useTheme, Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { tokens } from '../theme';
import { MenuOutlined } from '@mui/icons-material';
import User from '../assets/me.png';
import ImportantIcon from '../icons/important.png';
import BlockIcon from '../icons/BlockIcon.png';
import HomeIcon from '../icons/HomeIcon.png';
import MyStoryIcon from '../icons/MyStoryIcon.png';
import ComplaintIcon from '../icons/ComplaintIcon.png';
import TextIcon from '../icons/TextIcon.png';
import LetterIcon from '../icons/LetterIcon.png';
import ClaimIcon from '../icons/ClaimIcon.png';
import LetterIconIDOH from '../icons/LetterIconIDOH.png';
import LetterIconEMTALA from '../icons/LetterIconEMTALA.png';
import LetterIconAttorney from '../icons/LetterIconAttorney.png';
import MyResponseIcon from '../icons/MyResponseIcon.png';
import SettlementOfferIcon from '../icons/SettlementOfferIcon.png';
import ContactIcon from '../icons/ContactIcon.png';
import CommentIcon from '../icons/CommentIcon.png';
import EmailIcon from '../icons/EmailIcon.png';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

const SideBar = ({ isCollapsed, setIsCollapsed }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState('Home');

  const menuItems = [
    { title: 'IMPORTANT', icon: ImportantIcon, to: '/' },
    {
      title: 'BLOCKED ON LinkedIn',
      icon: BlockIcon,
      to: '/blockedOnLinkedIn',
    },
    { title: 'Home', icon: HomeIcon, to: '/home' },
    { title: 'My Story', icon: MyStoryIcon, to: '/myStory' },
    { title: 'Formal Complaint', icon: ComplaintIcon, to: '/formalComplaint' },
    { title: "Dr. Bustamante's Texts", icon: TextIcon, to: '/bustamanteTexts' },
    {
      title: "Dr. Bustamante's Emails",
      icon: EmailIcon,
      to: '/bustamanteEmails',
    },
    { title: "Betty Massey's Letters", icon: LetterIcon, to: '/bettyMassey' },
    { title: 'Their Claim', icon: ClaimIcon, to: '/theirClaim' },
    { title: "IDOH's Letter", icon: LetterIconIDOH, to: '/idohLetter' },
    { title: "EMTALA's Letter", icon: LetterIconEMTALA, to: '/emtalaLetter' },
    {
      title: "Attorney's Letter",
      icon: LetterIconAttorney,
      to: '/attorneyLetter',
    },
    { title: 'My Response', icon: MyResponseIcon, to: '/myResponse' },
    {
      title: 'Settlement Offer',
      icon: SettlementOfferIcon,
      to: '/settlementOffer',
    },
    { title: 'Contact Me', icon: ContactIcon, to: '/contact' },
    { title: 'Comments', icon: CommentIcon, to: '/comments' },
  ];

  return (
    <Box
      position="absolute"
      top="81px"
      bottom="51px"
      bgcolor={colors.primary[400]}
      width={isCollapsed ? '80px' : '250px'}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      {/* --------------------------------------------------------------------------- */}
      <Box
        sx={{
          position: 'absolute',
          right: isCollapsed ? '28px' : '5px',
          zIndex: 2,
        }}
      >
        <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
          <MenuOutlined />
        </IconButton>
      </Box>

      {/* Profile Sectiom */}
      {!isCollapsed && (
        <Box mb="25px" mt="60px">
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              src={User}
              alt="profile-user"
              width="120px"
              height="120px"
              style={{ cursor: 'pointer', borderRadius: '10px' }}
            />
          </Box>
          <Box textAlign="center">
            <Typography
              variant="h2"
              color={colors.grey[100]}
              fontWeight="bold"
              sx={{ m: '10px 0 0 0' }}
            >
              Faruk Ansari
            </Typography>
            <Typography variant="h5" color={colors.greenAccent[500]}>
              Victim
            </Typography>
          </Box>
        </Box>
      )}

      {/* --------------------------------------------------------------------------- */}

      <MenuList sx={{ mt: '30px' }}>
        {menuItems.map((item) => (
          <Tooltip
            key={item.title}
            title={isCollapsed ? item.title : ''}
            placement="right"
          >
            <MenuItem
              component={NavLink}
              to={item.to}
              sx={{
                color:
                  selected === item.title
                    ? colors.greenAccent[400]
                    : colors.grey[100],
                backgroundColor:
                  selected === item.title ? colors.primary[400] : 'transparent',
                '&:hover': {
                  color: colors.blueAccent[400],
                  bgcolor: colors.primary[400],
                },
              }}
              onClick={() => setSelected(item.title)}
            >
              <ListItemIcon sx={{ padding: '5px', paddingRight: '20px' }}>
                <img
                  src={item.icon}
                  alt={item.title}
                  style={{ width: '24px', height: '24px' }}
                />
              </ListItemIcon>
              {!isCollapsed && <ListItemText>{item.title}</ListItemText>}
            </MenuItem>
          </Tooltip>
        ))}
      </MenuList>
    </Box>
  );
};

export default SideBar;
