import React, { useContext } from 'react';
import { Box, Typography, IconButton, useTheme } from '@mui/material';
import { ColorModeContext, tokens } from '../theme';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';

const TopBar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      textAlign="center"
      position="absolute"
      left="0"
      right="0"
      top="0"
      height="80px"
      bgcolor={colors.primary[400]}
    >
      <Box
        sx={{
          border: 'solid',
          borderRadius: '6px',
          borderWidth: '1px',
          bgcolor: colors.blueAccent[500],
          marginLeft: '25px',
        }}
      >
        <Typography
          // variant="h2"
          fontWeight="bold"
          sx={{
            fontSize: {
              xs: '15px',
              sm: '20px',
              md: '30px',
              lg: '33px',
              xl: '36px',
            },
          }}
          padding="5px 20px"
          color={colors.grey[100]}
        >
          {/* CALLOUS - ASCENSION ST. VINCENT HOSPITALS - BEWARE */}
          ASCENSION ST. VINCENT HOSPITALS: UNSCRUPULOUS PRACTICES – BEWARE!
        </Typography>
      </Box>
      <Box display="flex" marginRight="25px">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === 'dark' ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
      </Box>
    </Box>
  );
};

export default TopBar;
