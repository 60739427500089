import React from 'react';
import {
  useTheme,
  Container,
  Box,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { tokens } from '../theme';
import Header from '../components/Header';
import Warning from '../components/Warning';
import BustamanteTextExchange from '../assets/BustammanteTexts.png';
import DrPeterBustamante from '../assets/DrPeterBustamante.jpeg';

const BustamanteTexts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container
      sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <Box
        sx={{ position: 'sticky', top: 0, zIndex: 1000, bgcolor: 'inherit' }}
      >
        <Warning />
      </Box>

      <Header
        title="TEXT EXCHANGE WITH DR. PETER BUSTAMANTE"
        subtile="Ascenson St. Vincent Hospitals: Unscrupulous - Beware - Text Exchange With Dr. Peter Bustamante"
      />

      <Paper
        elevation={5}
        sx={{
          bgcolor: colors.primary[400],
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '6px',
          paddingTop: '10px',
          paddingBottom: '10px',
          paddingLeft: '20px',
          paddingRight: '20px',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <img
          src={DrPeterBustamante}
          alt="DrPeterBustamante"
          width="120px"
          height="120px"
          style={{ borderRadius: '10px' }}
        />
        <Box
          sx={{
            marginLeft: '40px',
          }}
        >
          <Typography variant="h4" fontWeight="bold">
            DR. PETER BUSTAMANTE - REGIONAL CHIEF MEDICAL OFFICER | ASCENSION
            ST. VINCENT INDIANA
            <br />
            <br />
            <Typography
              variant="h4"
              fontWeight="bold"
              sx={{ color: colors.redAccent[500] }}
            >
              I had a few phone conversations, a brief text exchange, and
              several emails with him. However, after his text on October 20,
              2023, he disappeared, leaving behind an empty promise to resolve
              this matter to my satisfaction.
            </Typography>
          </Typography>
        </Box>
      </Paper>
      {/* Main content box */}

      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          flexGrow: 1,
          padding: 2,
        }}
      >
        <Paper
          elevation={5}
          sx={{
            bgcolor: colors.primary[400],
            borderRadius: '6px',
            padding: '20px',
            width: 'calc(100% + 40px)',
            // Top Right Bottom Left
            margin: '20px -20px 20px -20px',
          }}
        >
          <img
            src={BustamanteTextExchange}
            alt="Bustamante Text Exchange"
            style={{ borderRadius: '6px', width: '100%', height: 'auto' }}
          />
        </Paper>
      </Box>
    </Container>
  );
};

export default BustamanteTexts;
