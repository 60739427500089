import React from 'react';
import {
  useTheme,
  Container,
  Box,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { tokens } from '../theme';
import Header from '../components/Header';
import Warning from '../components/Warning';
import JosephImiccicheLinkedIn from '../assets/JosephImiccicheLinkedIn.png';
import RichFogalLinked from '../assets/RichFogalLinked.png';
import StacyHanefeldLinkedIn from '../assets/StacyHanefeldLinkedIn.png';
import OliviaLocklierLinkedIn from '../assets/OliviaLocklierLinkedIn.png';

const BlockedOnLinkedIn = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container
      sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <Box
        sx={{ position: 'sticky', top: 0, zIndex: 1000, bgcolor: 'inherit' }}
      >
        <Warning />
      </Box>

      <Header
        title="BLOCKED ON LINKEDIN"
        subtile="Ascenson St. Vincent Hospitals: Unscrupulous - Beware - Blocked On LinkedIn"
      />

      {/* Main content box */}
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          flexGrow: 1,
          padding: 2,
        }}
      >
        {/* NEW */}
        <Paper
          elevation={5}
          sx={{
            bgcolor: colors.primary[400],
            borderRadius: '6px',
            paddingTop: '20px',
            paddingLeft: '40px',
            paddingRight: '40px',
            paddingBottom: '20px',
            width: 'calc(100% + 40px)',
            margin: '20px -20px 20px -20px',
            justifyContent: 'center',
            textAlign: 'left',
            //
          }}
        >
          <Typography
            variant={isMobile ? 'h4' : 'h2'}
            color="error"
            gutterBottom
          >
            <strong>
              Ascension St. Vincent FIRST took down my website to silence my
              efforts to expose their negligence and unprofessionalism!
              <br />
              <br />
              The individuals listed below did not have the courage to respond
              to my posts so they chose to block me on LinkedIn also making it
              very difficult for me to post. It appears their intent was to
              prevent me from tagging them in my posts because they know my
              claim is true and they have nothing to say in thier defence.
              <br />
              <br />
              While this action speaks volumes, I remain committed to fostering
              transparent and constructive conversations.
              <br />
              <br />
              {/* <Typography variant={isMobile ? 'h4' : 'h3'}> */}
              <Box component="span" sx={{ color: colors.redAccent[500] }}>
                <a
                  href="https://www.linkedin.com/in/joseph-impicciche-0221b211/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: 'none',
                    color: colors.blueAccent[300],
                  }}
                >
                  1. JOSEPH IMPICCICHE - CHIEF EXECUTIVE OFFICER, ASCENSION
                </a>
              </Box>
              <br />
              <Box component="span" sx={{ color: colors.redAccent[500] }}>
                <a
                  href="https://www.linkedin.com/in/rich-fogel-03704634/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: 'none',
                    color: colors.blueAccent[300],
                  }}
                >
                  2. DR. RICH FOGAL - CHIEF CLINICAL & NETWORK SERVICES AT
                  ASCENSION
                </a>
              </Box>
              <br />
              {/* <br /> */}
              <Box component="span" sx={{ color: colors.redAccent[500] }}>
                <a
                  href="https://www.linkedin.com/in/olivia-locklier-95148526/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: 'none',
                    color: colors.blueAccent[300],
                  }}
                >
                  3. OLIVIA LOCKLIER - VICE PRESIDENT, HUMAN RESOURCES AT
                  ASCENSION
                </a>
              </Box>
              <br />
              <Box component="span" sx={{ color: colors.redAccent[500] }}>
                <a
                  href="https://www.linkedin.com/in/stacy-hanefeld-2a67b770/"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: 'none',
                    color: colors.blueAccent[300],
                  }}
                >
                  4. STACY HENEFELD - PARTNER AT DREWRY SIMMONS VORNEHM, LLP
                </a>
              </Box>
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Ascension St. Vincent's Attorney)
              <br />
              <br />
              Please check if you can access their LinkedIn profiles by either
              clicking on their names above or their LinkedIn profile
              screenshots below.
              <br />
              <br />I am sure this list will grow.
            </strong>
          </Typography>
          {/* NEW */}
          <Typography variant={isMobile ? 'h4' : 'h2'} paragraph>
            Please share, comment, and support the fight for justice.
          </Typography>
        </Paper>
        {/* NEW */}
        <Paper
          elevation={5}
          sx={{
            bgcolor: colors.primary[400],
            borderRadius: '6px',
            padding: '20px',
            margin: '0 -20px 0 -20px',
          }}
        >
          <a
            href="https://www.linkedin.com/in/joseph-impicciche-0221b211/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
              color: colors.blueAccent[300],
            }}
          >
            <img
              src={JosephImiccicheLinkedIn}
              alt="Joseph Imicciche LinkedIn"
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '6px',
              }}
            />
          </a>
        </Paper>
        <br />
        <Paper
          elevation={5}
          sx={{
            bgcolor: colors.primary[400],
            borderRadius: '6px',
            padding: '20px',
            margin: '0 -20px 0 -20px',
          }}
        >
          <a
            href="https://www.linkedin.com/in/rich-fogel-03704634/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
              color: colors.blueAccent[300],
            }}
          >
            <img
              src={RichFogalLinked}
              alt="Rich Fogal Linked"
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '6px',
              }}
            />
          </a>
        </Paper>
        <br />
        <Paper
          elevation={5}
          sx={{
            bgcolor: colors.primary[400],
            borderRadius: '6px',
            padding: '20px',
            margin: '0 -20px 0 -20px',
          }}
        >
          <a
            href="https://www.linkedin.com/in/olivia-locklier-95148526/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
              color: colors.blueAccent[300],
            }}
          >
            <img
              src={OliviaLocklierLinkedIn}
              alt="Olivia Locklier LinkedIn"
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '6px',
              }}
            />
          </a>
        </Paper>
        <br />
        <Paper
          elevation={5}
          sx={{
            bgcolor: colors.primary[400],
            borderRadius: '6px',
            padding: '20px',
            margin: '0 -20px 0 -20px',
          }}
        >
          <a
            href="https://www.linkedin.com/in/stacy-hanefeld-2a67b770/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: 'none',
              color: colors.blueAccent[300],
            }}
          >
            <img
              src={StacyHanefeldLinkedIn}
              alt="Stacy Hanefeld LinkedIn"
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '6px',
              }}
            />
          </a>
        </Paper>
      </Box>
    </Container>
  );
};

export default BlockedOnLinkedIn;
