import React from 'react';
import { Link } from 'react-router-dom';
import {
  useTheme,
  Container,
  Box,
  Paper,
  Typography,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { tokens } from '../theme';
import Header from '../components/Header';
import Warning from '../components/Warning';
import Finger from '../assets/MyFingerInjurySmall.jpg';

const MyStory = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Container
      sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <Box
        sx={{ position: 'sticky', top: 0, zIndex: 1000, bgcolor: 'inherit' }}
      >
        <Warning />
      </Box>

      <Header
        title="MY STORY"
        subtile="Ascenson St. Vincent Hospitals: Unscrupulous - Beware - My Story"
      />

      {/* Main content box */}
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          flexGrow: 1,
          padding: 2,
        }}
      >
        <Grid
          container
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Paper
            elevation={5}
            sx={{
              width: '100%',
              borderRadius: '6px',
              paddingBottom: '25px',
              bgcolor: colors.primary[400],
              marginBottom: '10px',
            }}
          >
            <Grid item xs={12}>
              <br />
              <Typography
                variant={isMobile ? 'h4' : 'h2'}
                component="p"
                fontWeight="550"
                paddingLeft="20px"
                paddingRight="20px"
                sx={{ textAlign: 'center', width: '100%' }}
              >
                My name is Faruk. I am a victim of Ascension St. Vincent
                hospital's{' '}
                <strong>
                  <br />
                  <Box component="span" sx={{ color: 'red' }}>
                    negligence{' '}
                  </Box>
                </strong>
                and{' '}
                <strong>
                  <Box component="span" sx={{ color: 'red' }}>
                    carelessness.
                  </Box>
                </strong>{' '}
                <br />
                <br />
                This is my story...
              </Typography>
              <Typography
                variant={isMobile ? 'h4' : 'h2'}
                fontWeight="550"
                component="p"
                // Top Right Bottom Left
                paddingLeft="20px"
                paddingRight="20px"
                sx={{ textAlign: 'center', width: '100%' }}
              >
                <br />
                They made an offer to me in leu of not taking any legal action
                against the hospital, which not only seemed like a joke but was
                outright{' '}
                <strong>
                  <Box component="span" sx={{ color: 'red' }}>
                    disrespectful
                  </Box>
                </strong>{' '}
                and{' '}
                <strong>
                  <Box component="span" sx={{ color: 'red' }}>
                    humiliating.
                  </Box>
                </strong>
                <br />
                <Typography variant={isMobile ? 'h4' : 'h3'}>
                  <Box component="span" sx={{ color: colors.redAccent[500] }}>
                    <Link
                      to="/settlementOffer"
                      style={{
                        textDecoration: 'none',
                        color: colors.blueAccent[300],
                      }}
                    >
                      <br />
                      🔗{}' read their attorney{' '}
                      <strong>
                        Stacy Hanefeld of Drewry Simmons Vornehm, LLP's
                      </strong>{' '}
                      offer letter
                    </Link>
                  </Box>
                </Typography>
              </Typography>
            </Grid>
          </Paper>
        </Grid>
        <Paper
          elevation={5}
          sx={{
            borderRadius: '6px',
            mt: '15px',
            bgcolor: colors.primary[400],
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              minHeight: '100vh',
              padding: 2,
            }}
          >
            {/* Column 1: Left Column */}
            <Grid item xs={12} md={6}>
              <Box paddingLeft="30px" paddingRight="30px">
                <Typography variant="h4" gutterBottom>
                  <strong>
                    A Story of Negligence at Ascension St. Vincent Hospitals:
                  </strong>
                </Typography>
                <br />
                <Typography variant="h5">
                  Before sharing my experience, I want to stress that I am not
                  alone in my suffering at the hands of Ascension St. Vincent
                  Hospitals.
                  <br />
                  <br />
                  I personally know two individuals who experienced similar
                  negligence at Ascension St. Vincent Carmel ER. A third
                  suffered even worse treatment at Ascension St. Vincent
                  Plainfield ER. These cases, coupled with my own, reflect a
                  troubling pattern of systemic failures at these hospitals.
                  <br />
                  <br />
                  One case of negligence is one too many. Yet, this hospital
                  continues to operate without accountability, putting countless
                  lives at risk.
                </Typography>
                <br />
                <br />
                <Typography variant="h4" gutterBottom>
                  <strong>Events of April 2, 2023:</strong>
                </Typography>
                <br />
                <Typography variant="h5">
                  On Sunday, April 2, 2023, while cutting wood in my shop, my
                  left pinky finger was severely injured by a table saw. The
                  injury occurred at approximately 10:30 AM.
                  <br />
                  <br />
                  In excruciating pain and bleeding heavily, I immediately
                  sought care at the closest hospital—Ascension St. Vincent
                  Carmel. I would soon realize that this decision was a{' '}
                  <strong>
                    <Box component="span" sx={{ color: 'red' }}>
                      grave mistake
                    </Box>
                    .
                  </strong>{' '}
                </Typography>
                <br />
                <br />
                <Typography variant="h4">
                  <strong>At Ascension St. Vincent Carmel, Emergency:</strong>
                  <br />
                  <br />
                </Typography>
                <Typography variant="h5">
                  I arrived at the emergency room around 10:45 AM, only to find
                  it completely empty. There was no one at the front desk. A
                  sign instructed me to use a red phone if no staff were
                  present.
                  <br />
                  <br />I picked up the phone and explained my critical
                  condition to the person on the line. Their response?{' '}
                  <strong>
                    <Box component="span" sx={{ color: 'red' }}>
                      "Please wait there and someone will be there shortly."
                    </Box>
                  </strong>{' '}
                  <br />
                  <br />
                  Several minutes passed, but no one arrived. As my condition
                  worsened, I became lightheaded. My driver called the staff a
                  second and third time, relaying the urgency of my situation,
                  yet the response remained the same.{' '}
                  <strong>
                    <Box component="span" sx={{ color: 'red' }}>
                      “Please wait there and someone will be there shortly.”
                    </Box>
                  </strong>{' '}
                  <br />
                  <br />
                  By the time nearly 30 minutes had passed without any
                  assistance,
                  <strong>
                    <Box component="span" sx={{ color: 'red' }}>
                      {' '}
                      I was in unbearable pain and feared for my life
                    </Box>
                    .
                  </strong>{' '}
                  I made the decision to leave and seek care at IU North
                  Emergency.
                  <br />
                  <br />
                  <br />
                </Typography>
                <Typography variant="h4">
                  <strong>IU North Emergency:</strong>
                  <br />
                  <br />
                </Typography>
                <Typography variant="h5">
                  <strong>
                    <Box component="span" sx={{ color: 'red' }}>
                      The contrast was night and day. Upon arriving at IU North,
                      I was immediately attended to. The staff prioritized my
                      care, administering painkillers, an anti-tetanus shot, and
                      stabilizing my injury.
                    </Box>
                  </strong>
                  <br />
                  <br />
                  Unfortunately, IU North did not have a hand surgeon on staff
                  and referred me to either Methodist Hospital or Ascension St.
                  Vincent 86th Street.
                  <br />
                  <br />
                  I was made to lay down while they talked to Methodist & St.
                  Vincent to make arrangements for me to see a Hand Surgeon. I
                  must have spent at least 3 hours at IU North between arrival,
                  initial treatment and their arrangements to locate a Hand
                  Surgeon.
                  <br />
                  <br />
                  <strong>
                    <Box component="span" sx={{ color: 'red' }}>
                      Still unaware of the consequences of choosing Ascension
                      St. Vincent, I opted for Ascension St. Vincent, 86th.
                      Street due to proximity. Another misfortune or another bad
                      decision
                    </Box>
                  </strong>
                  <br />
                  <br />
                  <br />
                </Typography>
                <Typography variant="h4">
                  <strong>
                    At Ascension St. Vincent Indianapolis, Trauma/Hand Surgery:
                  </strong>
                </Typography>
                <br />
                <Typography variant="h5">
                  Despite being told that Ascension St. Vincent was prepared to
                  treat me, I waited another hour and a half before being seen.
                  The surgery was performed by{' '}
                  <strong>
                    <Box component="span" sx={{ color: 'red' }}>
                      Dr. Spencer Moore
                    </Box>
                    ,
                  </strong>{' '}
                  a fellow, without input from an attending surgeon.
                  <br />
                  <br />
                  Shockingly, the possibility of saving my finger was not even
                  discussed. Dr. Moore proceeded with amputation without
                  presenting any alternative options.
                  <br />
                  <br />
                  Obviously, several hours had already been waisted by that time
                  and I did not have the mental or physical strength at the time
                  to seek a second opinion.
                </Typography>
                <br />
                <br />
                <Typography variant="h4">
                  <strong>The Aftermath</strong>
                </Typography>
                <Typography variant="h5">
                  <br />
                  In the days following my surgery, I sought the opinion of Dr.
                  Haroon Qazi, a renowned hand surgeon. He unequivocally stated
                  that my finger could have been saved if treated promptly and
                  appropriately.
                  <br />
                  <br />
                  Even after the surgery, my follow-up care at Ascension St.
                  Vincent was subpar. I was never seen by{' '}
                  <strong>
                    <Box component="span" sx={{ color: 'red' }}>
                      Dr. Spencer Moore
                    </Box>
                  </strong>{' '}
                  or any other doctor—only nurse practitioners.
                  <br />
                </Typography>
                <br />
                <Typography variant="h5">
                  If so much time was not wasted by the{' '}
                  <strong>
                    <Box component="span" sx={{ color: 'red' }}>
                      irresponsible St. Vincent staff
                    </Box>
                  </strong>
                  , I may still have the portion of my finger that was amputated
                  very easily and with no concern. If I had any idea about the{' '}
                  <strong>
                    <Box component="span" sx={{ color: 'red' }}>
                      professionalism at St. Vincent
                    </Box>
                  </strong>
                  , I would have definitely opted to go to Methodist Hospital
                  for my surgery.
                </Typography>
                <br />
                <br />
                <Typography variant="h4">
                  <strong>
                    Failed Attempts to Hold Ascension St. Vincent Accountable
                  </strong>
                </Typography>
                <br />
                <Typography variant="h5">
                  <strong>1. Initial Complaints:</strong>
                  <br />
                  <br />A few weeks after the injury, I mentioned the incident
                  to a friend Dr. Shahab Zaidi (Endocrinologist, previously
                  associated with Ascension St. Vincent Hospitals). One evening
                  we were at Dr. Zaidi’s house when I told him what happened at
                  the hospital. He was very upset to know about the incident and
                  right away called Ascension St. Vincent Hospital, Carmel
                  Emergency. He was connected to a lady, who I believe heads the
                  Emergency Services there. If I recall, her name was Mary.
                  First Dr. Zaidi explained to her what happened with me at the
                  Carmel, Emergency then I explained to her in complete detail.
                  She ensured me and Dr. Zaidi that she will investigate the
                  situation, look at the recordings of the cameras in the
                  Emergency lobby and will get back to us. Neither Dr. Zaidi nor
                  me ever heard back from her.
                </Typography>
                <br />
                {/* <Typography variant="h5">
                  <strong>2. Subsequent Follow-ups:</strong>
                  <br />
                  <br />
                  I believe after waiting for several weeks, Dr. Zaidi talked to
                  someone at Ascension St. Vincent Hospital and as a result, I
                  received a call from another lady. She said she was the head
                  of Ambulatory Services at Ascension St. Vincent Hospitals. I
                  explained the whole incident to her. She said the same thing
                  that, she will investigate the situation, look at the
                  recordings of the cameras in the Emergency lobby and will get
                  back to me. I never ever heard back from her either.
                  <br />
                  <br />
                </Typography> */}
              </Box>
            </Grid>

            {/* Column 2 and Column 3: Right Side */}
            <Grid item xs={12} md={6}>
              <Grid container direction="column" spacing={2}>
                {/* Column 2 */}
                <Grid item xs={12} md={6}>
                  <Box
                    component="img"
                    src={Finger}
                    sx={{
                      height: 'auto', // Responsive height
                      width: '93%', // Maintain proportion
                      paddingLeft: '30px',
                    }}
                  />
                </Grid>

                {/* Column 3 */}
                <Grid item>
                  <Box paddingLeft="30px" paddingRight="30px">
                    {/* <Typography variant="h4">
                      <strong>
                        Failed Attempts to Hold Ascension St. Vincent
                        Accountable
                      </strong>
                    </Typography>
                    <br />
                    <Typography variant="h5">
                      <strong>1. Initial Complaints:</strong>
                      <br />
                      <br />A few weeks after the injury, I mentioned the
                      incident to a friend Dr. Shahab Zaidi (Endocrinologist,
                      previously associated with Ascension St. Vincent
                      Hospitals). One evening we were at Dr. Zaidi’s house when
                      I told him what happened at the hospital. He was very
                      upset to know about the incident and right away called
                      Ascension St. Vincent Hospital, Carmel Emergency. He was
                      connected to a lady, who I believe heads the Emergency
                      Services there. If I recall, her name was Mary. First Dr.
                      Zaidi explained to her what happened with me at the
                      Carmel, Emergency then I explained to her is complete
                      detail. She ensured me and Dr. Zaidi that she will
                      investigate the situation, look at the recordings of the
                      cameras in the Emergency lobby and will get back to us.
                      Neither Dr. Zaidi nor me ever heard back from her.
                    </Typography>
                    <br />
                    // <Typography variant="h5">
                    //   <strong>2. Subsequent Follow-ups:</strong>
                    //   <br />
                    //   <br />
                    //   I believe after waiting for several weeks, Dr. Zaidi
                    //   talked to someone at Ascension St. Vincent Hospital and as
                    //   a result, I received a call from another lady. She said
                    //   she was the head of Ambulatory Services at Ascension St.
                    //   Vincent Hospitals. I explained the whole incident to her.
                    //   She said the same thing that, she will investigate the
                    //   situation, look at the recordings of the cameras in the
                    //   Emergency lobby and will get back to me. I never ever
                    //   heard back from her either.
                    //   <br />
                    //   <br />
                    // </Typography> */}
                    <Typography variant="h5" gutterBottom>
                      {/* Text Column 3 */}
                      <strong>3. Formal Complaint:</strong>
                      <br />
                      <br />
                      It seemed like Ascension St. Vincent Hospitals was trying
                      to keep a low key in this situation, thinking that if they
                      ignore this incident, it will eventually go away and will
                      be forgotten. No one in authority at the hospital till
                      that time even tried to reach out with an apology.
                      <br />
                      <br />
                      I got very frustrated of Ascension St. Vincent Hospital's
                      efforts to ignore me and finally decided to file a formal
                      complaint against them with the American Hospital
                      Association and the Indiana Department of Health.
                      <br />
                      <Link
                        to="/formalComplaint"
                        style={{
                          textDecoration: 'none',
                          color: colors.blueAccent[300],
                        }}
                      >
                        <br />
                        🔗 <strong>Read my formal complaint...</strong>
                      </Link>
                    </Typography>
                    <br />
                    {/* <br /> */}
                    <Typography variant="h4">
                      <strong>After the Formal Complaint:</strong>
                    </Typography>
                    <br />
                    <Typography variant="h5">
                      As soon as I filed the Formal Complaint and emailed a copy
                      of the complaint to{' '}
                      <strong>
                        <Box component="span" sx={{ color: 'red' }}>
                          Dr. Peter J. Bustamante (Regional Chief Medical
                          Officer Ascension St. Vincent),
                        </Box>
                      </strong>{' '}
                      I get a phone call from him.
                      <br />
                      <br />
                      He assured me that he will investigate and get me in touch
                      with the right people and that he will make sure that this
                      matter is resolved to my satisfaction.
                      <br />
                      <br />
                      Dr. Bustamante called me a few times and corresponded with
                      me via email and text. He told me numerous times that I
                      can contact him via email, phone call or text with any
                      issues relating to my case, but once I was connected to
                      their attorney, I was told by her not to communicate with
                      Dr. Bustamante or any other staff of the hospital. I did
                      text Dr. Bustamante after that but never heard back from
                      him.
                      <br />
                      <br />
                      Their attorney also asked for my consent for them to get
                      my medical records from IU Health ER, Carmel and Indiana
                      Hand to Shoulder Center. I gave the consent, however it
                      took them several weeks to obtain the records, whereas, I
                      personally requested the records from both entities and
                      received them within the hour.
                    </Typography>
                    <br />
                    <br />
                    <Typography variant="h4">
                      <strong>Ongoing Consequences:</strong>
                    </Typography>
                    <br />
                    <Typography variant="h5">
                      Pain and suffering was not the only consequence I faced
                      due to the{' '}
                      <strong>
                        <Box component="span" sx={{ color: 'red' }}>
                          irresponsible behavior of St. Vincent.
                        </Box>
                      </strong>{' '}
                      I am an Information Technology professional and was an
                      Owner of a car dealership. I often times work on vehicles
                      to make them ready for sale. I was unable to work on
                      anything mechanical for months and as a result I lost my
                      income, my reputation and eventually my business. I
                      suffered immensely by not being able to deliver the
                      promised vehicles on time.
                      <br />
                      <br />
                      My software development business suffered in the same way.
                      I was unable to deliver the projects on time and as a
                      consequence, my reputation suffered greatly. I was denied
                      new projects because I could not deliver the ones I was
                      working on, on time.
                      <br />
                      <br />
                      After over twenty months I am still struggling. My finger
                      is still hyper-sensitive to touch, and the missing part of
                      the finger, has presented me with challenges using the
                      keyboard to work on my software development projects. I
                      make a lot of mistakes while typing and trying to retrain
                      my other finger to do the work of the missing finger. This
                      has put me behind several months, greatly impacting my
                      ability to make a living and effecting my reputation
                      because of not being able to deliver on time.
                    </Typography>
                    <br />
                    <br />
                    <Typography variant="h4">
                      <strong>
                        Result of Investigation by American Hospital Association
                        and Indiana Department of Health:
                      </strong>
                    </Typography>
                    <br />
                    <Typography variant="h5">
                      {/* I received letters from the Department of Health & Human
                      Services and the Indiana Department of Health, both of
                      them after their investigation determined St. Vincent to
                      be at fault.
                      <br /> */}
                      {/* <br /> */}I received letters from the{' '}
                      <Link
                        to="/emtalaLetter"
                        style={{
                          textDecoration: 'none',
                          color: colors.blueAccent[300],
                        }}
                      >
                        🔗{' '}
                        <strong>Department of Health & Human Services</strong>
                      </Link>{' '}
                      and the{' '}
                      <Link
                        to="/idohLetter"
                        style={{
                          textDecoration: 'none',
                          color: colors.blueAccent[300],
                        }}
                      >
                        🔗 <strong>Indiana Department of Health</strong>
                      </Link>
                      ,{' '}
                      <strong>
                        <Box component="span" sx={{ color: 'red' }}>
                          both of them after their investigation determined
                          Ascension St. Vincent to be at fault.
                        </Box>
                      </strong>
                      <br />
                      <br />
                    </Typography>
                    <br />
                    <Typography variant="h4">
                      <strong>A Systemic Failure:</strong>
                    </Typography>
                    <br />
                    <Typography variant="h5">
                      <strong>
                        <Box component="span" sx={{ color: 'red' }}>
                          Even after Ascension St. Vincent was found at fault,
                          their response was deeply disheartening. Instead of
                          genuine accountability, they offered a meager
                          settlement in exchange for my silence. This is how
                          large hospitals exploit the system, prioritizing their
                          reputation over patient care.
                        </Box>
                      </strong>
                      <br />
                      <br />
                      Please note, what{' '}
                      <Link
                        to="/settlementOffer"
                        style={{
                          textDecoration: 'none',
                          color: colors.blueAccent[300],
                        }}
                      >
                        🔗{' '}
                        <strong>
                          Ascension St. Vincent Hospital offered me
                        </strong>
                      </Link>{' '}
                      to give up my rights to take them to the court, and not
                      take any action against them.
                      <br />
                    </Typography>
                    <br />
                    <br />
                    <Typography variant="h4">
                      <strong>A Call for Change:</strong>
                    </Typography>
                    <br />
                    <Typography variant="h5">
                      My experience underscores the urgent need for
                      accountability and reform within Ascension St. Vincent
                      Hospitals. Lives depend on it. I share my story not just
                      for myself but for the countless others who may have
                      suffered—or will suffer—due to their negligence. Hospitals
                      are meant to heal, not harm. It's time Ascension St.
                      Vincent lived up to that promise.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {/* </Paper> */}
      </Box>
    </Container>
  );
};

export default MyStory;
