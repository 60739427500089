import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ColorModeContext, useMode } from './theme';
import { CssBaseline, Box, ThemeProvider } from '@mui/material';
import SideBar from './components/SideBar';
import TopBar from './components/TopBar';
import Footer from './components/Footer';
import Important from './pages/Important';
import BlockedOnLinkedIn from './pages/BlockedOnLinkedIn';
import Home from './pages/Home';
import MyStory from './pages/MyStory';
import FormalComplaint from './pages/FormalComplaint';
import BustamanteTexts from './pages/BustamanteTexts';
import BustamanteEmails from './pages/BustamanteEmails';
import BettyMasseyCorrespondance from './pages/BettyMasseyCorrespondance';
import TheirClaim from './pages/TheirClaim';
import IDOHLetter from './pages/IDOHLetter';
import EMTALALetter from './pages/EMTALALetter';
import AttorneyLetter from './pages/AttorneyLetter';
import MyResponse from './pages/MyResponse';
import SettlementOffer from './pages/SettlementOffer';
import Contact from './pages/Contact';
import Comments from './pages/Comments';

function App() {
  const [theme, colorMode] = useMode();
  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box display="flex" height="100vh" flexDirection="column">
          {/* Main Content Area */}
          <Box display="flex" flex="1" overflow="hidden" position="relative">
            {/* SideBar */}
            <SideBar
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
            />

            {/* Main Content */}
            <Box
              component="main"
              flexGrow={1}
              marginLeft={isCollapsed ? '65px' : '250px'} // Adjust dynamically
              transition="margin-left 0.3s ease" // Smooth transition
              overflow="auto"
              height="calc(100vh - 52px)" // Adjust for TopBar and Footer
              paddingTop="50px" // TopBar height
            >
              <TopBar />
              <Routes>
                <Route path="/" element={<Important />} />
                <Route
                  path="/blockedOnLinkedIn"
                  element={<BlockedOnLinkedIn />}
                />
                <Route path="/home" element={<Home />} />
                <Route path="/myStory" element={<MyStory />} />
                <Route path="/formalComplaint" element={<FormalComplaint />} />
                <Route path="/bustamanteTexts" element={<BustamanteTexts />} />
                <Route
                  path="/bustamanteEmails"
                  element={<BustamanteEmails />}
                />
                <Route
                  path="/bettyMassey"
                  element={<BettyMasseyCorrespondance />}
                />
                <Route path="/theirClaim" element={<TheirClaim />} />
                <Route path="/idohLetter" element={<IDOHLetter />} />
                <Route path="/emtalaLetter" element={<EMTALALetter />} />
                <Route path="/attorneyLetter" element={<AttorneyLetter />} />
                <Route path="/myResponse" element={<MyResponse />} />
                <Route path="/settlementOffer" element={<SettlementOffer />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/comments" element={<Comments />} />
              </Routes>
            </Box>
          </Box>

          {/* Footer */}
          <Box flexShrink={0}>
            <Footer />
          </Box>
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
