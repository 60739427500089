import React from 'react';
import { useTheme, Container, Box, Paper } from '@mui/material';
import { tokens } from '../theme';
import Header from '../components/Header';
import Warning from '../components/Warning';
import MyResponse1 from '../assets/MyResponse1.png';
import MyResponse2 from '../assets/MyResponse2.png';

const MyResponse = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Container
      sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <Box
        sx={{ position: 'sticky', top: 0, zIndex: 1000, bgcolor: 'inherit' }}
      >
        <Warning />
      </Box>

      <Header
        title="MY RESPONSE"
        subtile="Ascenson St. Vincent Hospitals: Unscrupulous - Beware - My Response"
      />

      {/* Main content box */}
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          flexGrow: 1,
          padding: 2,
        }}
      >
        {/* Paper with content */}
        {[MyResponse1, MyResponse2].map((imgSrc, idx) => (
          <Paper
            elevation={5}
            sx={{
              bgcolor: colors.primary[400],
              borderRadius: '6px',
              padding: '20px',
              width: 'calc(100% + 40px)',
              // Top Right Bottom Left
              margin: '0 -20px 20px -20px',
            }}
          >
            <img
              src={imgSrc}
              alt={`My Response ${idx + 1}`}
              style={{ borderRadius: '6px', width: '100%', height: 'auto' }}
            />
          </Paper>
        ))}
      </Box>
    </Container>
  );
};

export default MyResponse;
