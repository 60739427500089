import React from 'react';
import { Paper, Typography, Box, useTheme, useMediaQuery } from '@mui/material';
import Attention from '../assets/Attention.png';
import { tokens } from '../theme';

const Warning = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper
      elevation={5}
      sx={{
        bgcolor: 'red',
        border: 'solid',
        borderRadius: '6px',
        borderWidth: '1px',
        padding: isMobile ? '10px' : '15px',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        marginLeft: 'auto ',
        marginRight: 'auto',
        marginTop: '45px',
        marginBottom: '30px',
        wordWrap: 'break-word',
        display: {
          xs: 'flex',
          md: 'flex',
        },
      }}
    >
      <Typography
        color="white"
        fontWeight="bold"
        // sx={{ fontSize: { xs: '16px', md: '27px' } }}
        variant={isMobile ? 'h4' : 'h3'}
        sx={{
          wordWrap: 'break-word',
          overflowWrap: 'break-word',
        }}
      >
        This is being shared to inform and safeguard the residents across 16
        states and 396 cities served by Ascension. <br />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={Attention}
            alt="Attention"
            style={{ width: '70px', height: '60px' }}
          />
          <span
            style={{
              paddingLeft: '15px',
              paddingRight: '15px',
            }}
          >
            SMALL VICTORY!!! <br />
            <Typography
              sx={{
                color: 'dodgerblue',
                fontSize: { xs: '16px', md: '27px' },
                fontWeight: 'bold',
              }}
            >
              🔗{' '}
              <a
                href="https://www.ascensionstvincent.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: 'none',
                  color: colors.blueAccent[400],
                }}
              >
                AscensionStVincent.com
              </a>
            </Typography>
            is ONCE AGAIN back up and running after being brought down TWICE by
            the COWARDS at Ascension St. Vincent.
          </span>
          <img
            src={Attention}
            alt="Attention"
            style={{ width: '70px', height: '60px' }}
          />
        </Box>
      </Typography>
    </Paper>
  );
};

export default Warning;
